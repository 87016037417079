import { createRouter, createWebHistory } from 'vue-router';
import axiosWrapper from '@/assets/axios-wrapper';
import { isLoggedIn, logOut } from '../store/authStore';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/create-recipe',
    name: 'Create Recipe',
    component: () => import('../views/create-recipe.vue'),
  },
  {
    path: '/search-recipes',
    name: 'Search Recipes',
    component: () => import('../views/search-recipes.vue'),
  },
  {
    path: '/recipes/:recipeId',
    name: 'View Recipe',
    component: () => import('../views/view-recipe.vue'),
    props: true,
  },
  {
    path: '/edit-recipe/:recipeId',
    name: 'Edit Recipe',
    component: () => import('../views/edit-recipe.vue'),
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: () => import('../views/not-found.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Redirect to login page, if not logged in OR if current session is invalid
router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);

  if (!authRequired) {
    // public page, go ahead.
    next();
  } else if (!isLoggedIn()) {
    // trying to access a restricted page + not logged in
    // redirect to login page
    next('/login');
  } else {
    // trying to access a restricted page + logged it
    // verify token validity
    axiosWrapper()
      .get('/auth')
      .then(() => {
        // token is valid, go ahead.
        next();
      })
      .catch(() => {
        // token is invalid, log out and redirect to login
        logOut();
        next('/login');
      });
  }
});

export default router;
