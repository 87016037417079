function isLoggedIn() {
  return localStorage.getItem('userToken') !== null;
}

function logIn(token) {
  localStorage.setItem('userToken', token);
}

function logOut() {
  localStorage.removeItem('userToken');
}

function getToken() {
  return localStorage.getItem('userToken');
}

export {
  getToken, isLoggedIn, logIn, logOut,
};
