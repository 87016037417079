import axios from 'axios';
import { getToken, isLoggedIn } from '../store/authStore';
import baseApiUrl from './base-uri';

const axiosWrapper = () => {
  let headers = {};
  if (isLoggedIn()) {
    headers = {
      authorization: `Bearer ${getToken()}`,
    };
  }

  return axios.create({
    baseURL: baseApiUrl,
    headers,
  });
};

export default axiosWrapper;
