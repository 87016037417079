let baseURL;

console.log(process.env);
if (process.env.NODE_ENV === 'development') {
  baseURL = `http://localhost:${process.env.VUE_APP_API_PORT}/api`;
} else {
  baseURL = `${window.location.origin}/api`;
}

const baseApiUrl = baseURL;

export default baseApiUrl;
